import React from "react";

const VacancyDeleteModal = ({ onClose, handleDelete }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-50">
      <div className="modal bg-white px-5 rounded-xl py-6 w-[50%]">
        <div className="modal-content overflow-y-auto">
          <div className="flex justify-between items-center">
            <h1 className="title-color text-xl font-semibold tracking-wide uppercase">
              Delete Vacancy
            </h1>
          </div>
          <div className="py-2">
            Are you sure you want to delete this vacancy detail?
          </div>
          <div className="flex justify-evenly pt-3">
            <button
              className="bg-red-600 flex text-white px-3 py-1.5 rounded-lg hover:bg-red-700"
              onClick={handleDelete}
            >
              Delete Vacancy
            </button>
            <button
              className="bg-green-800 flex text-white px-3 py-1.5 rounded-lg hover:bg-green-900"
              onClick={onClose}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VacancyDeleteModal;
