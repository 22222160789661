import React, { useState } from "react";
import DashboardSidebar from "../../components/DashboardSidebar";
import VacancyTable from "../../components/VacancyTable";
import ViewClientTable from "../../components/ViewClientTable";
import AddClientTable from "../../components/AddClientTable";
import ClientInquiryFormTable from "../../components/ClientInquiryFormTable";

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState("Add Vacancy");

  const Tabs = [
    { tabName: "Add Vacancy" },
    { tabName: "Add Client" },
    { tabName: "View CV" },
    { tabName: "View Inquiry Form" },
  ];
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="flex ">
        <DashboardSidebar
          Tabs={Tabs}
          activeTab={activeTab}
          handleTabChange={handleTabChange}
        />
        <div className="w-full pl-[200px]  ">
          {activeTab === "Add Vacancy" && <VacancyTable />}
          {activeTab === "Add Client" && <AddClientTable />}
          {activeTab === "View CV" && <ViewClientTable />}
          {activeTab === "View Inquiry Form" && <ClientInquiryFormTable />}
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
