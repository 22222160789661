import React from "react";
import CompanyForm from "../CompanyForm";

const CompanyModal = ({ onClose, handleFormSubmit, clientDetails }) => {
  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-500 bg-opacity-80">
      <div className="modal bg-white overflow-y-auto rounded-xl h-[86vh] w-[50%] ">
        <div className="modal-content overflow-y-auto">
          <div className="flex justify-between items-center px-6 ">
            <h1 className="title-color text-xl font-bold tracking-wide uppercase py-5 text-green-700 ">
              Add Company
            </h1>
            <div
              className="close cursor-pointer text-4xl font-bold"
              onClick={onClose}
            >
              &times;
            </div>
          </div>
          <CompanyForm
            onFormSubmit={handleFormSubmit}
            clientDetails={clientDetails}
          />
        </div>
      </div>
    </div>
  );
};

export default CompanyModal;
