import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import VacancyCard from "../../components/VacancyCard";

const Vacancies = () => {
  const [selectedVacancy, setSelectedVacancy] = useState([]);
  const [jobVacancy, setJobVacancy] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchJobVacancy = async () => {
      try {
        const vacancyRef = collection(db, "vacancies");
        const vacancySnapshot = await getDocs(vacancyRef);
        const vacancy = vacancySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobVacancy(vacancy);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching travel vacancy:", error);
      }
    };

    fetchJobVacancy();
  }, []);

  return (
    <div id="vacancy" className="bg-green-50 px-5 md:px-14 pb-20">
      {loading ? (
        <div className="bg-green-50 min-h-screen flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-gray-800">Loading...</p>
          <img src="/loader.svg" alt="loader" width={150} className="mx-auto" />
        </div>
      ) : (
        <>
          <div
            className="text-center title-color text-3xl font-semibold tracking-wide
          uppercase py-3 bg-green-800 text-white fixed overflow-hidden w-[90%] md:w-11/12 "
          >
            Our Vacancies
          </div>

          <div className="flex flex-col md:flex-row md:flex-wrap gap-5 py-4 gap-y-6 pt-[30%] sm:pt-[20%] md:pt-[8%]">
            {jobVacancy.map((vacancy, index) => (
              <VacancyCard
                key={vacancy.jobTitle}
                jobTitle={vacancy.jobTitle}
                image={vacancy.detailImage}
                vacancyLink={`/vacancy/${vacancy.id}`}
                applyLink={`/apply?vacancy=${vacancy.jobTitle}`}
                setSelectedVacancy={setSelectedVacancy}
              />
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Vacancies;
