import React from "react";
// import { FaPhone, FaEnvelope, FaMapMarkerAlt } from "react-icons/fa";
import InquiryForm from "../../components/InquiryForm";
import { ToastContainer } from "react-toastify";

const ClientInquiryForm = () => {
  return (
    <div className="flex flex-col px-5 min-h-screen md:items-center bg-green-50 pb-10">
      <ToastContainer />
      <h1 className="text-center text-green-800 title-color text-3xl font-semibold tracking-wide uppercase py-5">
        Client Inquiry Form
      </h1>
      <InquiryForm />
    </div>
  );
};

export default ClientInquiryForm;
