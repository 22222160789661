import React from "react";

const ClientCard = ({ clientName, website, address, image, clientDesc }) => {
  return (
    <a href={website} target="_blank" rel="noreferrer">
      <div className="flex items-center border md:h-[8vh] border-gray-200 rounded-lg shadow  w-full md:w-[327px] xl:w-[428px] bg-gray-900 hover:opacity-80">
        <img className="rounded-xl max-w-[60px] p-3" src={image} alt="" />
        <h5 className="mb-2 font-bold tracking-tight text-gray-900 dark:text-white line-clamp-3">
          {clientName}
        </h5>
      </div>
    </a>
  );
};

export default ClientCard;
