import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import "./index.css";

const ImageSlider = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrevious = () => {
    setCurrentImageIndex(
      (prevIndex) => (prevIndex - 1 + images.length) % images.length
    );
  };

  const handleDotClick = (index) => {
    setCurrentImageIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="image-slider shadow-md shadow-[#aacede] rounded-sm flex flex-col justify-center items-center">
      {images.length > 0 && (
        <div className="flex flex-col gap-2 pb-5 px-10">
          <div className="relative">
            <img
              src={images[currentImageIndex]}
              alt={images[currentImageIndex]}
              className="slider-image h-screen w-full"
              onClick={() => setIsModalOpen(true)}
            />

            <div className="navigation-buttons">
              <button className="left-button" onClick={handlePrevious}>
                &lt;
              </button>
              <button className="right-button" onClick={handleNext}>
                &gt;
              </button>
            </div>
            <div className="dots py-4 flex justify-center">
              {images.map((image, index) => (
                <span
                  key={index}
                  className={index === currentImageIndex ? "dot active" : "dot"}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>

            <Modal
              isOpen={isModalOpen}
              onRequestClose={() => setIsModalOpen(false)}
              contentLabel="Enlarged Image"
              className="modal"
              overlayClassName="overlay"
            >
              <img
                src={images[currentImageIndex]}
                alt={images[currentImageIndex]}
                className="enlarged-image"
              />
            </Modal>
          </div>
          <div className="image-description justify-center flex text-justify ">
            {images[currentImageIndex].description}
          </div>
          <div className="flex justify-center">
            <button
              className="bg-green-800 text-white text-sm rounded-md py-2 px-2"
              onClick={() => setIsModalOpen(true)}
            >
              View Full Image
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ImageSlider;
