import React from "react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import ApplyForm from "./../../components/ApplyForm/index";

const ApplyNow = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const selectedVacancy = searchParams.get("vacancy");

  return (
    <div className="flex flex-col px-5 min-h-screen md:items-center bg-green-50 pb-10">
      <ToastContainer />
      <h1 className="text-center text-green-800 title-color text-3xl font-semibold tracking-wide uppercase py-5">
        Apply Now
      </h1>
      <ApplyForm selectedVacancy={selectedVacancy} />
    </div>
  );
};

export default ApplyNow;
