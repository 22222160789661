import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { collection, getDocs, doc, deleteDoc } from "firebase/firestore";
import { db } from "../../view/firebaseConfig";
import VacancyDeleteModal from "./../Company/Vacancy/VacancyDeleteModal/index";
import VacancyModal from "../Company/Vacancy/VacancyModal";

const VacancyTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [clients, setClients] = useState([]);
  const [clientToDelete, setClientToDelete] = useState(null);
  const [editClientData, setEditClientData] = useState(null);

  const fetchClients = async () => {
    try {
      const clientRef = collection(db, "vacancies");
      const clientSnapshot = await getDocs(clientRef);
      const clientData = clientSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setClients(clientData);
    } catch (error) {
      console.log("Error fetching clients", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const handleModal = () => {
    setEditClientData(null);
    setIsModalOpen(true);
  };

  const handleFormSubmit = () => {
    fetchClients();
    setIsModalOpen(false);
  };

  const openEditModal = (client) => {
    setEditClientData(client);
    setIsModalOpen(true);
  };

  const openDeleteModal = (client) => {
    setClientToDelete(client);
    setDeleteModal(true);
  };

  const handleDelete = async () => {
    try {
      if (clientToDelete) {
        const clientRef = doc(db, "vacancies", clientToDelete.id);
        await deleteDoc(clientRef);
        toast.error("Client deleted successfully!");

        // Update the clients state after deletion
        setClients((prevClients) =>
          prevClients.filter((c) => c.id !== clientToDelete.id)
        );
      }
    } catch (error) {
      toast.error("Error deleting client:", error);
    }
    setClientToDelete(null);
    setDeleteModal(false);
  };

  return (
    <div className="overflow-x-auto">
      <ToastContainer />

      <div className="flex justify-between px-5 py-3">
        <h1 className="text-center title-color text-2xl font-semibold tracking-wide uppercase text-green-800 ">
          Vacancy Table
        </h1>
        <button
          className="bg-green-800 flex text-white px-4 py-2 rounded-lg hover:bg-green-900"
          onClick={handleModal}
        >
          Add Vacancy
        </button>
      </div>
      {isModalOpen && (
        <VacancyModal
          onClose={() => setIsModalOpen(false)}
          handleFormSubmit={handleFormSubmit}
          clientDetails={editClientData}
        />
      )}

      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              S.N
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Job Title
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Company Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Feature
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Country
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Position
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Number of Vacancy
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Number of Male
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Number of Female
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Detail Image
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Edit Client
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Delete Client
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {clients.map((client, index) => (
            <tr key={index} className="h-12">
              <td className="pl-10 py-4 w-1/12">{index + 1}</td>
              <td className="px-6 py-4 w-2/5">{client.jobTitle || "-"}</td>
              <td className="px-6 py-4 w-2/5">{client.companyName || "-"}</td>
              <td className="px-6 py-4 w-2/5">
                {client.isFeatured === true ? "True" : "False"}
              </td>
              <td className="px-6 py-4 w-2/5">{client.country || "-"}</td>
              <td className="px-6 py-4 w-2/5">{client.position || "-"}</td>
              <td className="px-6 py-4 w-1/5">{client.vacancyNumber || "-"}</td>
              <td className="px-6 py-4 w-1/5">{client.male || "-"}</td>
              <td className="px-6 py-4 w-1/5">{client.female || "-"}</td>
              <td className="px-6 py-4 w-2/5">
                <img
                  src={client.detailImage || "-"}
                  alt=""
                  className="h-12 w-12"
                />
              </td>
              <td className="px-6 py-4 w-2/5">
                <button
                  className="bg-green-800 flex text-white px-3 py-2 rounded-lg hover:bg-green-900"
                  onClick={() => openEditModal(client)}
                >
                  Edit Vacancy
                </button>
              </td>
              <td className="px-6 py-4 w-2/5">
                <button
                  className="bg-green-800 flex text-white px-3 py-2 rounded-lg hover:bg-green-900"
                  onClick={() => openDeleteModal(client)}
                >
                  Delete Vacancy
                </button>
              </td>
            </tr>
          ))}
        </tbody>
        {deleteModal && (
          <VacancyDeleteModal
            onClose={() => setDeleteModal(false)}
            handleDelete={handleDelete}
          />
        )}
      </table>
    </div>
  );
};

export default VacancyTable;
