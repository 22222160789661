import React, { useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db, storage } from "../../view/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

const initialValues = {
  name: "",
  email: "",
  contact: "",
  applyingFor: "",
  specializations: "",
  selectedVacancy: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  contact: Yup.string()
    .matches(/^\d+$/, "Invalid contact number")
    .required("Contact is required"),
  applyingFor: Yup.string().required("Position is required"),
  specializations: Yup.string().required("Specializations is required"),
  selectedVacancy: Yup.string(),
});

const ApplyForm = ({ selectedVacancy }) => {
  const [file, setFile] = useState("");
  const [percent, setPercent] = useState(0);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  const formData = new FormData();
  formData.append("file", file);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    if (isSubmittingForm) return;

    try {
      setIsSubmittingForm(true);
      setSubmitting(true);
      if (!file) {
        toast.error("Please upload an image first!");
        setIsSubmittingForm(false);
        return;
      }

      const response = await axios.post("https://formspree.io/f/xeqbopde", {
        values,
        selectedVacancy,
      });

      const fileName = `${values.name}${file.name.substr(
        file.name.lastIndexOf(".")
      )}`;

      const storageRef = ref(storage, `/files/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );

          setPercent(percent);
        },
        (err) => console.log(err),
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const timestamp = new Date();

          const formSubmissionsRef = collection(db, "formSubmissions");
          await addDoc(formSubmissionsRef, {
            ...values,
            selectedVacancy,
            file: downloadURL,
            timestamp: timestamp,
          });

          const formData = new FormData();
          formData.append("downloadURL", JSON.stringify(downloadURL));
          formData.append("values", values);
          formData.append("selectedVacancy", selectedVacancy);

          if (response.status === 200) {
            toast.success("Message sent successfully!");
            resetForm({ values: initialValues });
            setIsSubmittingForm(false);
            setSubmitting(false);
            const fileInput = document.getElementById("file");
            if (fileInput) {
              fileInput.value = null;
            }
          } else {
            toast.error("Error sending message. Please try again.");
          }
          setIsSubmittingForm(false);
          setSubmitting(false);
        }
      );
    } catch (error) {
      toast.error("Error sending message. Please try again.");
      setIsSubmittingForm(false);
      setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="max-w-md font-medium text-base px-8 py-5 rounded-lg shadow-sm border">
          <div className="mb-4">
            <label htmlFor="name">Name</label>
            <Field
              type="text"
              id="name"
              name="name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email">Email</label>
            <Field
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="contact">Contact Number</label>
            <Field
              type="text"
              id="contact"
              name="contact"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="contact"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="applyingFor">Applying For</label>
            <Field
              type="text"
              id="applyingFor"
              name="applyingFor"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="applyingFor"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="specializations">Specializations</label>
            <Field
              type="text"
              id="specializations"
              name="specializations"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="specializations"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="message">Upload Resume</label>
            <input
              id="file"
              name="file"
              type="file"
              accept=".png, .jpg, .jpeg, .pdf, .doc, .docx"
              onChange={handleChange}
              className="cursor-pointer "
            />
          </div>
          {percent > 0 && <p> Uploading : {percent} %</p>}
          {selectedVacancy && (
            <div>
              <label htmlFor="selectedVacancy">Selected Job</label>
              <Field
                type="text"
                id="selectedVacancy"
                name="selectedVacancy"
                value={selectedVacancy}
                readOnly
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
              />
            </div>
          )}
          <button
            type="submit"
            disabled={isSubmitting || isSubmittingForm}
            className="disabled:bg-green-900 disabled:cursor-not-allowed mt-4 ml-[38%] px-8 py-3 bg-green-800 rounded-md text-white text-base font-bold hover:bg-green-900"
          >
            {isSubmitting || isSubmittingForm ? "Submitting" : "Submit"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default ApplyForm;
