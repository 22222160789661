import React, { useEffect, useState } from "react";
import worker from "../../assets/images/employment-agencies.png";
import { Link, NavLink } from "react-router-dom";
import Footer from "../../components/Footer";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";

export default function Home() {
  const [jobVacancy, setJobVacancy] = useState([]);

  useEffect(() => {
    const fetchJobVacancy = async () => {
      try {
        const vacancyRef = collection(db, "vacancies");
        const vacancySnapshot = await getDocs(vacancyRef);
        const vacancy = vacancySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setJobVacancy(vacancy);
      } catch (error) {
        console.error("Error fetching travel vacancy:", error);
      }
    };

    fetchJobVacancy();
  }, []);

  const maxVacanciesToShow = 5;
  return (
    <>
      <div className="bg-green-50 px-5 flex flex-col gap-5">
        <div className="flex flex-col md:flex-row md:justify-around items-center pt-2 ">
          <div className="text-center md:w-6/12 ">
            <h1 className="text-2xl md:text-[38px] xl:text-5xl font-bold tracking-tight text-gray-900">
              Leader In International Recruitment.
            </h1>
            <div>
              <img src={worker} alt="worker" className="md:hidden" />
            </div>
            <p className=" text-lg leading-8 text-gray-600 pt-4 ">
              A leading placement consultant providing recruitment and man power
              assistance to clients across segments. Endowed by the experience
              of our team, we are a leader in international recruitment
              services, multinational recruiting and career placements.
            </p>
            <div className="py-3 gap-3 flex flex-col">
              <div className=" flex flex-row items-center justify-center gap-x-6">
                <a
                  href="/inquiry"
                  className="rounded-md bg-green-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Employers
                </a>
                <NavLink
                  to="/vacancy"
                  activeClassName="active"
                  className="rounded-md bg-green-700 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-green-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                >
                  Job Seekers
                </NavLink>
              </div>
            </div>
          </div>
          <div>
            <img
              src={worker}
              alt="worker"
              className="hidden md:block md:max-w-xl"
            />
          </div>
        </div>
        <div className="md:px-14 pb-5">
          <div className="flex justify-between items-center ">
            <div className=" text-green-500 flex gap-2 text-lg md:text-2xl uppercase">
              <div cla>Our</div>
              <div className="font-bold text-green-700">Vacancies</div>
            </div>
            <Link
              to="/vacancy"
              className="border-green-700 border-2 px-2 py-0.5 font-bold text-sm text-green-700 rounded-sm "
            >
              VIEW ALL
            </Link>
          </div>
          <div className="flex flex-col md:flex-row md:flex-wrap gap-5 py-4 ">
            {jobVacancy.slice(0, maxVacanciesToShow).map(
              (vacancy, index) =>
                vacancy?.isFeatured === true && (
                  <Link
                    to={`/apply?vacancy=${vacancy.jobTitle}`}
                    className="flex items-center hover:bg-green-50 cursor-pointer px-3 rounded-lg md:h-[20vh] xl:w-[329px] lg:w-[252px] shadow border"
                  >
                    <img
                      class="object-cover md:rounded-sm"
                      src={vacancy.detailImage}
                      alt=""
                      width={50}
                    />
                    <div class="flex flex-col justify-between p-4 leading-normal">
                      <h5 class="mb-2 text-base font-bold tracking-tight text-green-800 line-clamp-2 capitalize">
                        {vacancy.jobTitle}
                      </h5>
                      <p class="mb-3 font-normal text-gray-700 dark:text-gray-400 line-clamp-6 capitalize">
                        {vacancy.companyName}
                      </p>
                    </div>
                  </Link>
                )
            )}
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
