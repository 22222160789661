import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db, storage } from "../../view/firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import DataTable from "../common/DataTable.index";

const ClientInquiryFormTable = () => {
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    try {
      const clientRef = collection(db, "inquiryForm");
      const clientSnapshot = await getDocs(
        query(clientRef, orderBy("timestamp", "desc"))
      );
      const clientDataPromises = clientSnapshot.docs.map(async (doc) => {
        const client = {
          id: doc.id,
          ...doc.data(),
        };
        if (client?.file) {
          const storageRef = ref(storage, client.file);
          const downloadURL = await getDownloadURL(storageRef);
          client.downloadURL = downloadURL;
        }
        return client;
      });
      const clientData = await Promise.all(clientDataPromises);
      setClients(clientData);
    } catch (error) {
      console.log("Error fetching clients", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const columns = [
    {
      header: "S.N",
      accessorKey: "id",
      cell: (info) => info.row.index + 1,
    },

    {
      header: "Client Name",
      accessorKey: "name",
    },
    {
      header: "Company Name",
      accessorKey: "companyName",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Contact Number",
      accessorKey: "contact",
    },
    {
      header: "Subject",
      accessorKey: "subject",
    },
    {
      header: "Message",
      accessorKey: "message",
    },
  ];

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-between px-5 py-3">
        <h1 className="text-center title-color text-2xl font-semibold tracking-wide uppercase text-green-800 ">
          View Client Inquiry Details
        </h1>
      </div>

      <DataTable columns={columns} data={clients} />
    </div>
  );
};

export default ClientInquiryFormTable;
