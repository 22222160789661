import React from "react";
import { Link } from "react-router-dom";
import "./index.css";

const VacancyCard = ({
  jobTitle,
  image,
  setSelectedVacancy,
  vacancyLink,
  applyLink,
}) => {
  const handleApplyClick = () => {
    setSelectedVacancy(jobTitle);
  };

  return (
    <div className=" border-gray-300 border-[0.1px] px-6 py-5 rounded-lg shadow w-full md:w-[357px] xl:w-[410px]">
      <div className="text-3xl font-bold text-green-700 pb-4 capitalize text-ellipsis h-[110px] overflow-hidden">
        {jobTitle}
      </div>
      <img
        src={image}
        alt=""
        width={500}
        className="naxatw-h-[50vh] max-h-[70vh]"
      />
      <div className="flex justify-evenly pt-2">
        <Link to={vacancyLink}>
          <button className="bg-green-800 text-white px-4 py-2 rounded-lg hover:bg-green-900">
            View Details
          </button>
        </Link>
        <Link
          to={applyLink}
          onClick={handleApplyClick}
          className="bg-green-800 text-white px-4 py-2 rounded-lg hover:bg-green-900"
        >
          Apply Now
        </Link>
      </div>
    </div>
  );
};

export default VacancyCard;
