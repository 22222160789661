import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { db } from "../../view/firebaseConfig";
import { collection, addDoc } from "firebase/firestore";

const initialValues = {
  name: "",
  email: "",
  contact: "",
  companyName: "",
  subject: "",
  message: "",
};

const validationSchema = Yup.object({
  name: Yup.string().required("Name is required"),
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
  contact: Yup.string()
    .matches(/^\d+$/, "Invalid contact number")
    .required("Contact is required"),
  companyName: Yup.string().required("Company name is required"),
  subject: Yup.string().required("Subject is required"),
  message: Yup.string().required("Message is required"),
});

const InquiryForm = () => {
  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const timestamp = new Date();
      const inquiryFormRef = collection(db, "inquiryForm");
      await addDoc(inquiryFormRef, {
        ...values,
        timestamp: timestamp,
      });

      const response = await axios.post(
        "https://formspree.io/f/xknlgpez",
        values
      );

      if (response.status === 200) {
        toast.success("Message sent successfully!");
        resetForm({ values: initialValues });
      } else {
        toast.error("Error sending message. Please try again.");
      }
    } catch (error) {
      toast.error("Error sending message. Please try again.");
    }
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="max-w-md font-medium text-base px-8 py-5 rounded-lg shadow border">
          <div className="mb-4">
            <label htmlFor="name">Name</label>
            <Field
              type="text"
              id="name"
              name="name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email">Email</label>
            <Field
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="contact">Contact Number</label>
            <Field
              type="text"
              id="contact"
              name="contact"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="contact"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="companyName">Company Name</label>
            <Field
              type="text"
              id="companyName"
              name="companyName"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="companyName"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="subject">Subject</label>
            <Field
              type="text"
              id="subject"
              name="subject"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="subject"
              component="div"
              className="text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="message">Message</label>
            <Field
              as="textarea"
              id="message"
              name="message"
              rows="4"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="message"
              component="div"
              className="text-red-500"
            />
          </div>

          <button
            type="submit"
            disabled={isSubmitting}
            className="mt-4 ml-[38%] px-8 py-3 bg-green-800 rounded-md text-white text-base font-bold hover:bg-green-900"
          >
            {isSubmitting ? "Submitting..." : "Send"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default InquiryForm;
