import React from "react";

const RecruitMentUI = () => {
  return (
    <div className=" min-h-screen">
      <div className="container">
        <div className="flex flex-col">
          <h1 className="text-2xl md:text-4xl font-bold mb-4">
            Why Recruit From Us ?
          </h1>

          <div className="container mx-auto mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <FeatureCard>
              <h3 className="font-semibold text-base">Sufficient Database</h3>
              <img
                className="rounded-full w-[80px] h-[80px] mx-auto "
                src="sufficient-databse.png"
                alt="sufficient-databse"
              />
              Strong capability in providing database of qualified candidates as
              per requirements of Clients with full responsibility.
            </FeatureCard>
            <FeatureCard>
              <h3 className="font-semibold text-base">Strong team work</h3>
              <img
                className="rounded-full w-[80px] h-[80px] mx-auto"
                src="strong-team-work.jpg"
                alt="strong-team-work"
              />
              Working with Service Oriented, highly competent and responsible
              team.
            </FeatureCard>
            <FeatureCard>
              <h3 className="font-semibold text-base">
                Cost-effective service
              </h3>
              <div>
                <img
                  className="rounded-full w-[80px] h-[80px] mx-auto"
                  src="cost-effective.png"
                  alt="cost-effective"
                />
              </div>
              <div>
                Quick, Simple & Cost-effective recruitment process with low RSC
                (Recruitment Service Charge).
              </div>
            </FeatureCard>
            <FeatureCard>
              <h3 className="font-semibold text-base">
                Hassle-free legal procedure
              </h3>
              <img
                className="rounded-full w-[80px] h-[80px] mx-auto"
                src="hassle-free-mindset.png"
                alt="hassele-free-mindset"
              />
              Committed to furnish all legal procedure within a week to arrange
              deployment within 10 working days of visa receiving.
            </FeatureCard>
            <FeatureCard>
              <h3 className="font-semibold text-base">Customer satisfaction</h3>
              <img
                className="rounded-full w-[80px] h-[80px] mx-auto"
                src="customer-satisfaction-survey.jpg"
                alt="hassele-free-mindset"
              />
              Able to meet the criteria of customers on providing Human Resource
              as per their need.
            </FeatureCard>
            <FeatureCard>
              <h3 className="font-semibold text-base">Equal opportunity:</h3>
              <img
                className="rounded-full w-[80px] h-[80px] mx-auto"
                src="equal-opportunity.png"
                alt="equal-opportunity"
              />
              We are providing equal opportunity for all categories of workers
              Skilled / Unskilled / Semi Skilled & Professional personnel's
              tie-up with skill training and testing center.
            </FeatureCard>
          </div>
        </div>
      </div>
    </div>
  );
};
const FeatureCard = ({ children }) => {
  return (
    <div className="border rounded-lg shadow p-4 ">
      <p className="text-sm text-justify">{children}</p>
    </div>
  );
};
export default RecruitMentUI;
