import React, { useEffect, useState } from "react";
import { db } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import ClientCard from "../../components/ClientCard";

const OurClients = () => {
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientRef = collection(db, "ourClient");
        const clientSnapshot = await getDocs(clientRef);
        const clientData = clientSnapshot.docs.map((doc) => doc.data());
        setClients(clientData);
      } catch (error) {
        console.log("Error fetching clients", error);
      }
    };
    fetchClients();
  }, []);

  return (
    <div className="bg-green-50 px-5 md:px-14 pb-20 min-h-[100vh] ">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full lg:w-full flex flex-col justify-center">
          <h1 className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-5">
            Our Clients
          </h1>
          <p className="font-normal text-base leading-6 text-gray-600 text-justify">
            We are an executive search and recruitment company providing
            international placement services. Our excellence is in providing
            highly qualified and experienced professionals for senior, middle
            and junior management level. Our specialization is in understanding
            company needs and providing candidates with relevant experience and
            skills to meet your needs. Our mission is to recruit responsible
            people in respect of vacancy and company needs. Today's highly
            competitive world requires experienced, skilled and hardworking
            professionals to get an edge in the rat race. We partner you in this
            endeavor for providing professionals.
          </p>
        </div>
      </div>
      <div className="w-full lg:w-full flex flex-col justify-center pt-14 gap-2">
        <h1 className="text-2xl md:text-4xl text-justify font-bold leading-9 text-gray-800">
          Meet The Companies That Trust R.K. International to Deliver The Best.
        </h1>
        <div className="flex flex-col md:flex-row md:flex-wrap gap-10 py-4 gap-y-6 ">
          {clients.map((client, index) => (
            <div key={index}>
              <ClientCard
                image={client.logo}
                website={client.website}
                clientDesc={client.description}
                clientName={client.name}
                address={client.address}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurClients;
