import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { db, storage } from "../../../../view/firebaseConfig";

const initialValues = {
  jobTitle: "",
  position: "",
  country: "",
  vacancyNumber: "",
  male: "",
  female: "",
  companyName: "",
  isFeatured: false,
};
const validationSchema = Yup.object({
  jobTitle: Yup.string().required("Job title is required"),
  country: Yup.string().required("Country is required"),
  vacancyNumber: Yup.string().required("Vacancy Number is required"),
  position: Yup.string().required("Position is required"),
  companyName: Yup.string().required("Company name is required"),
});

const VacancyForm = ({ onFormSubmit, clientDetails }) => {
  const [detailImage, setDetailImage] = useState("");
  const [percent, setPercent] = useState(0);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const formikRef = useRef(); // Create a ref for the Formik instance

  useEffect(() => {
    if (clientDetails) {
      setDetailImage("");
      setPercent(0);
      resetFormValues(clientDetails, false); // Reset the form when clientDetails are provided, without resetting the detailImage field
    } else {
      resetFormValues(initialValues, true); // Reset the form and the detailImage field when no client details are provided
    }
  }, [clientDetails]);

  const resetFormValues = (initialValues) => {
    if (formikRef.current) {
      formikRef.current.setValues(initialValues);
      formikRef.current.setErrors({});
      formikRef.current.setTouched({});
    }
  };

  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    if (isSubmittingForm) return;

    try {
      setIsSubmittingForm(true);
      setSubmitting(true);

      if (detailImage) {
        const storageRef = ref(storage, `/vacancy/${detailImage.name}`);
        const uploadTask = uploadBytesResumable(storageRef, detailImage);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            setPercent(percent);
          },
          (err) => console.log(err),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            if (clientDetails) {
              // Perform PATCH request if clientDetails exist
              await updateClient({
                ...values,
                detailImage: downloadURL,
              });
            } else {
              // Perform POST request if clientDetails doesn't exist
              await addClient({
                ...values,
                detailImage: downloadURL,
              });
            }

            toast.success("Vacancy saved successfully!");
            resetForm({ values: initialValues });

            // Call the callback function to update the table data
            onFormSubmit();

            resetFormValues(initialValues, true);
            setPercent(0);
            setIsSubmittingForm(false);
            setSubmitting(false);
          }
        );
      } else {
        if (clientDetails) {
          // Perform PATCH request if clientDetails exist
          await updateClient(values);
        } else {
          // Perform POST request if clientDetails doesn't exist
          await addClient(values);
        }

        toast.success("Vacancy saved successfully!");
        resetForm({ values: initialValues });

        // Call the callback function to update the table data
        onFormSubmit();

        resetFormValues(initialValues);
        setDetailImage("");
        setPercent(0);
        setIsSubmittingForm(false);
        setSubmitting(false);
      }
    } catch (error) {
      toast.error("Error saving vacancy. Please try again.");
      setIsSubmittingForm(false);
      setSubmitting(false);
    }
  };

  const addClient = async (values) => {
    const inquiryFormRef = collection(db, "vacancies");
    await addDoc(inquiryFormRef, {
      ...values,
      createdAt: serverTimestamp(),
    });
  };

  const updateClient = async (values) => {
    const clientRef = doc(db, "vacancies", clientDetails.id);
    await updateDoc(clientRef, values);
  };

  const handleLogoChange = (event) => {
    setDetailImage(event.target.files[0]);
  };

  const handleDeleteLogo = async () => {
    try {
      const storageRef = ref(storage, `${clientDetails.detailImage}`);
      await deleteObject(storageRef);

      const clientRef = doc(db, "vacancies", clientDetails.id);
      await updateDoc(clientRef, { detailImage: "" });

      toast.success("Logo deleted successfully!");
    } catch (error) {
      console.error(error); // Log the error to the console for debugging
      toast.error("Error deleting detailImage. Please try again.");
    }
  };

  return (
    <Formik
      innerRef={formikRef} // Assign the ref to the Formik instance
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="font-medium text-base shadow-2xl px-8 py-5 rounded-lg drop-shadow-2xl flex-1">
          <div className="mb-4">
            <label htmlFor="name">Job title</label>
            <Field
              type="text"
              id="jobTitle"
              name="jobTitle"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="jobTitle"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 flex flex-col ">
            <label htmlFor="detailImage">Upload Image</label>
            {clientDetails && clientDetails.detailImage && (
              <div>
                <img
                  id="file"
                  src={clientDetails.detailImage}
                  alt="Client Logo"
                  className="w-24 h-24 mb-2"
                />
                <button
                  type="button"
                  onClick={handleDeleteLogo}
                  className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  Delete Logo
                </button>
              </div>
            )}
            <input
              id="detailImage"
              name="detailImage"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleLogoChange}
              className="cursor-pointer "
            />

            {percent > 0 && <p> Uploading : {percent} %</p>}

            <ErrorMessage
              name="detailImage"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="companyName">Company Name</label>
            <Field
              type="text"
              id="companyName"
              name="companyName"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="companyName"
              component="div"
              className="text-red-500"
            />
          </div>

          <div className="mb-4 flex items-center gap-4 ">
            <label htmlFor="isFeatured">Is Featured?</label>
            <Field
              id="isFeatured"
              name="isFeatured"
              type="checkbox"
              className="form-checkbox h-5 w-5 text-green-600"
            />
            <ErrorMessage
              name="isFeatured"
              component="div"
              className="text-red-500"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="position">Position</label>
            <Field
              type="position"
              id="position"
              name="position"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="position"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="country">Country</label>
            <Field
              type="text"
              id="country"
              name="country"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="country"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="vacancyNumber">Number of vacancy</label>
            <Field
              type="text"
              id="vacancyNumber"
              name="vacancyNumber"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="vacancyNumber"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="male">Number of Male</label>
            <Field
              id="male"
              name="male"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="male"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="male">Number of Female</label>
            <Field
              id="female"
              name="female"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="female"
              component="div"
              className="text-red-500"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting || isSubmittingForm}
            className="disabled:bg-green-900 disabled:cursor-not-allowed mt-4 ml-[38%] px-8 py-3 bg-green-800 rounded-md text-white text-base font-bold hover:bg-green-900"
          >
            {isSubmitting || isSubmittingForm ? "Submitting" : "Submit"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default VacancyForm;
