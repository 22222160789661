import React from "react";
import Pan from "../../assets/images/pan.jpg";
import Seal from "../../assets/images/seal.jpg";
import Document from "../../assets/images/document.jpg";
import PanCertificate from "../../assets/images/pancertificate.jpg";
import Permission from "../../assets/images/permisssion.jpeg";
import Registar from "../../assets/images/registar.jpg";
import License from "../../assets/images/license.jpg";
import ImageSlider from "../../components/common/ImageSlider";

const images = [
  Pan,
  Seal,
  Document,
  PanCertificate,
  Permission,
  Registar,
  License,
];

const LegalDocumentsUI = () => {
  return (
    <div className="min-h-screen py-8 ">
      <div className="container ">
        <h1 className="text-2xl md:text-4xl font-bold mb-4">Legal Documents</h1>
        <ImageSlider images={images} />
      </div>
    </div>
  );
};

export default LegalDocumentsUI;
