import React from "react";

const RequiredDocumentsUI = () => {
  return (
    <div className="min-h-screen text-base">
      <div className="container">
        <h1 className="text-2xl md:text-4xl font-bold pb-4 md:pl-5">
          Documentation
        </h1>
        <div className="rounded-lg border flex flex-col gap-2 shadow-md p-6 text-justify">
          <h1 className="text-2xl font-bold mb-1">Required Documents</h1>
          <p>
            The Department of Foreign Employment (DOFE) in Nepal and concerned
            embassy/consulates require certain documents to endorse visa and to
            grant the permission for foreign employment in Nepal. As per the
            rules and regulation, such document must be duly prepared in the
            letter head of the employer, duly stamped and signed by the
            authorized person of the employer. Followings are the major
            documents & their brief description:
          </p>
          <p>
            This principal document should clearly state all the requirement
            details and recruitment terms and conditions such as required number
            of workers, their qualification / criteria, salary, accommodation,
            transportation, contract period, working hours, food and other
            provisions.
          </p>
          <ul className="list-decimal ml-3 mt-2 flex flex-col gap-3">
            <li>
              <h2 className="text-lg font-semibold">Demand Letter</h2>
              <p>
                This is the commitment letter from Employer accepting all the
                terms and conditions stated in the demand letter and in the
                agreement paper. Breach of the terms and conditions of the
                letter will blacklist the employer for future recruitment from
                Nepal as well as legal action against the employer can be taken
                in the country of employment.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Power of Attorney</h2>
              <p>
                This principal document gives us the authority to complete all
                the recruitment formalities at the embassies, airports, labour
                offices or wherever seems necessary.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">
                Employment Agreement Paper
              </h2>
              <p>
                This is the legal contract between the employer and the
                employee. The agreement paper should describe all the major
                terms and conditions of the employment such as position, salary,
                accommodation, transportation, contract period, working hours,
                food and other provisions as per the requirement of DOFE in
                Nepal and as per the labour laws of the country of employment.
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Agency Agreement</h2>
              <p>
                This is the formal agreement signed by both the employer and
                recruiter. It should be prepared in the letterhead of the
                employer and should be signed by the authorized persons of both
                the parties (employer and Recruiter in Nepal).
              </p>
            </li>
            <li>
              <h2 className="text-lg font-semibold">Guarantee Letter</h2>
              <p>
                This is the commitment letter provided to the DOFE in Nepal with
                regards to the deployment of Nepalese workers in the said
                countries and companies only.
              </p>
            </li>
          </ul>
          <p className="mt-4">
            As the document and the samples vary according to the country,
            please contact us along with your requirement details, for the
            samples so that we could assist you to prepare them.
          </p>
        </div>
      </div>
    </div>
  );
};

export default RequiredDocumentsUI;
