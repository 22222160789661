import React, { useEffect, useState } from "react";
import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { db, storage } from "../../view/firebaseConfig";
import { getDownloadURL, ref } from "firebase/storage";
import DataTable from "../common/DataTable.index";
import { format } from "date-fns";

const ViewClientTable = () => {
  const [clients, setClients] = useState([]);

  const fetchClients = async () => {
    try {
      const clientRef = collection(db, "formSubmissions");
      const clientSnapshot = await getDocs(
        query(clientRef, orderBy("timestamp", "desc"))
      );
      const clientDataPromises = clientSnapshot.docs.map(async (doc) => {
        const client = {
          id: doc.id,
          ...doc.data(),
        };
        if (client?.file) {
          const storageRef = ref(storage, client.file);
          const downloadURL = await getDownloadURL(storageRef);
          client.downloadURL = downloadURL;
        }

        return client;
      });
      const clientData = await Promise.all(clientDataPromises);
      setClients(clientData);
    } catch (error) {
      console.log("Error fetching clients", error);
    }
  };

  useEffect(() => {
    fetchClients();
  }, []);

  const columns = [
    {
      header: "S.N",
      accessorKey: "id",
      cell: (info) => info.row.index + 1,
    },

    {
      header: "Client Name",
      accessorKey: "name",
    },
    {
      header: "Vacancy Name",
      accessorKey: "selectedVacancy",
    },
    {
      header: "Email",
      accessorKey: "email",
    },
    {
      header: "Contact Number",
      accessorKey: "contact",
    },
    {
      header: "Position / Applied for",
      accessorKey: "applyingFor",
    },
    {
      header: "Specializations",
      accessorKey: "specializations",
    },
    {
      header: "Applied Date",
      accessorKey: "timestamp",
      cell: (info) => {
        const timestamp = info.getValue();
        if (!timestamp) return "-";
        const date = new Date(
          timestamp.seconds * 1000 + timestamp.nanoseconds / 1e6
        );
        return format(date, "dd MMMM yyyy, h:mm:ss a");
      },
    },
    {
      header: "Resume/CV",
      accessorKey: "downloadURL",
      cell: (info) =>
        info.getValue() ? (
          <a
            href={info.getValue()}
            target="_blank"
            rel="noopener noreferrer"
            download
            className="bg-green-800 flex text-white px-4 py-2 rounded-lg hover:bg-green-900"
          >
            View CV
          </a>
        ) : (
          "-"
        ),
    },
  ];

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-between px-5 pt-3">
        <h1 className="text-center title-color text-2xl font-semibold tracking-wide uppercase text-green-800 ">
          View Client Details
        </h1>
      </div>

      <DataTable data={clients} columns={columns} />
    </div>
  );
};

export default ViewClientTable;
