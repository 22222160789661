import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import Home from "./view/Home/index";
import AboutUs from "./view/AboutUs/index";
import OurClients from "./view/OurClients/index";
import Vacancies from "./view/Vacancies/index";
import ClientInquiryForm from "./view/ClientInquiryForm/index";
import VacancyPost from "./components/VacancyPost";
import Login from "./view/Login";
import AdminDashboard from "./view/AdminDashboard";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "./view/firebaseConfig";
import ApplyNow from "./view/ApplyNow";
import { ToastContainer } from "react-toastify";

const App = () => {
  const [user] = useAuthState(auth);
  const currentUser = user !== null;

  const RequireAuth = ({ children }) => {
    return currentUser ? (
      children
    ) : (
      <Navigate
        to="/login"
        replace
        state={{ from: window.location.pathname }}
      />
    );
  };

  return (
    <Router>
      <div>
        <ToastContainer />
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          {/* <Route path="/employers" element={<Employers />} /> */}
          <Route path="/clients" element={<OurClients />} />
          <Route path="/vacancy/:id" element={<VacancyPost />} />
          <Route exact path="/vacancy" element={<Vacancies />} />
          <Route exact path="/apply" element={<ApplyNow />} />
          <Route path="/inquiry" element={<ClientInquiryForm />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/dashboard"
            element={
              <RequireAuth>
                <AdminDashboard />
              </RequireAuth>
            }
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
