import React, { useState } from "react";
import { auth } from "../../view/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const Login = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();

    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        if (user) {
          navigate("/dashboard");
          toast.success("Login successful!");
        }
      })
      .catch((error) => {
        const errorCode = error.code;
        let errorMessage = "Invalid username or password";
        if (errorCode === "auth/user-not-found") {
          errorMessage = "User not found";
        } else if (errorCode === "auth/wrong-password") {
          errorMessage = "Wrong password";
        }
        toast.error(errorMessage);
        // setError(true);
      });
  };

  return (
    <div className="flex justify-center pt-16 ">
      <form
        onSubmit={handleLogin}
        className="max-w-md p-6 bg-white rounded-lg shadow shadow-gray-400"
      >
        <h2 className="text-2xl font-bold mb-6">Admin Login</h2>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        <div className="mb-4">
          <label htmlFor="email" className="block mb-2 font-medium">
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            required
          />
        </div>
        <div className="mb-4">
          <label htmlFor="password" className="block mb-2 font-medium">
            Password
          </label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            required
          />
        </div>
        <button
          type="submit"
          className="w-full px-4 py-2 bg-green-800 rounded-md text-white text-base font-bold hover:bg-green-900"
        >
          Login
        </button>
      </form>
    </div>
  );
};

export default Login;
