import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  collection,
  addDoc,
  doc,
  updateDoc,
  serverTimestamp,
} from "firebase/firestore";
import { db, storage } from "../../../view/firebaseConfig";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";

const initialValues = {
  name: "",
  email: "",
  website: "",
  address: "",
  description: "",
};
const validationSchema = Yup.object({
  name: Yup.string().required("Client Name is required"),
  email: Yup.string().email("Invalid email address"),
  website: Yup.string().url("Invalid website URL"),
  address: Yup.string().required("Address is required"),
});

const CompanyForm = ({ onFormSubmit, clientDetails }) => {
  const [logo, setLogo] = useState("");
  const [percent, setPercent] = useState(0);

  const [isSubmittingForm, setIsSubmittingForm] = useState(false);

  const formikRef = useRef(); // Create a ref for the Formik instance

  useEffect(() => {
    if (clientDetails) {
      setLogo("");
      setPercent(0);
      resetFormValues(clientDetails, false); // Reset the form when clientDetails are provided, without resetting the logo field
    } else {
      resetFormValues(initialValues, true); // Reset the form and the logo field when no client details are provided
    }
  }, [clientDetails]);

  const resetFormValues = (initialValues) => {
    if (formikRef.current) {
      formikRef.current.setValues(initialValues);
      formikRef.current.setErrors({});
      formikRef.current.setTouched({});
    }
  };
  const handleSubmit = async (values, { resetForm, setSubmitting }) => {
    if (isSubmittingForm) return;

    try {
      setIsSubmittingForm(true);
      setSubmitting(true);
      if (logo) {
        const storageRef = ref(storage, `/client/${logo.name}`);
        const uploadTask = uploadBytesResumable(storageRef, logo);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            setPercent(percent);
          },
          (err) => console.log(err),
          async () => {
            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);

            if (clientDetails) {
              // Perform PATCH request if clientDetails exist
              await updateClient({
                ...values,
                logo: downloadURL,
              });
            } else {
              // Perform POST request if clientDetails doesn't exist
              await addClient({
                ...values,
                logo: downloadURL,
              });
            }

            toast.success("Client saved successfully!");
            resetForm({ values: initialValues });

            // Call the callback function to update the table data
            onFormSubmit();

            resetFormValues(initialValues, true);
            setPercent(0);
            setIsSubmittingForm(false);
            setSubmitting(false);
          }
        );
      } else {
        if (clientDetails) {
          // Perform PATCH request if clientDetails exist
          await updateClient(values);
        } else {
          // Perform POST request if clientDetails doesn't exist
          await addClient(values);
        }

        toast.success("Client saved successfully!");
        resetForm({ values: initialValues });

        // Call the callback function to update the table data
        onFormSubmit();

        resetFormValues(initialValues);
        setLogo("");
        setPercent(0);
        setIsSubmittingForm(false);
        setSubmitting(false);
      }
    } catch (error) {
      toast.error("Error saving client. Please try again.");
      setIsSubmittingForm(false);
      setSubmitting(false);
    }
  };

  const addClient = async (values) => {
    const inquiryFormRef = collection(db, "ourClient");
    await addDoc(inquiryFormRef, {
      ...values,
      createdAt: serverTimestamp(),
    });
  };

  const updateClient = async (values) => {
    const clientRef = doc(db, "ourClient", clientDetails.id);
    await updateDoc(clientRef, values);
  };

  const handleLogoChange = (event) => {
    setLogo(event.target.files[0]);
  };

  const handleDeleteLogo = async () => {
    try {
      const storageRef = ref(storage, `${clientDetails.logo}`);
      await deleteObject(storageRef);

      const clientRef = doc(db, "ourClient", clientDetails.id);
      await updateDoc(clientRef, { logo: "" });

      toast.success("Logo deleted successfully!");
    } catch (error) {
      console.error(error); // Log the error to the console for debugging
      toast.error("Error deleting logo. Please try again.");
    }
  };

  return (
    <Formik
      innerRef={formikRef} // Assign the ref to the Formik instance
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => (
        <Form className="font-medium text-base shadow-2xl px-8 py-5 rounded-lg drop-shadow-2xl flex-1">
          <div className="mb-4">
            <label htmlFor="name">Client Name</label>
            <Field
              type="text"
              id="name"
              name="name"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="name"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4 flex flex-col ">
            <label htmlFor="logo">Upload Logo</label>
            {clientDetails && clientDetails.logo && (
              <div>
                <img
                  id="file"
                  src={clientDetails.logo}
                  alt="Client Logo"
                  className="w-24 h-24 mb-2 "
                />

                <button
                  type="button"
                  onClick={handleDeleteLogo}
                  className="px-2 py-1 bg-red-500 text-white rounded-md hover:bg-red-600"
                >
                  Delete Logo
                </button>
              </div>
            )}
            <input
              className="cursor-pointer"
              id="logo"
              name="logo"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={handleLogoChange}
            />

            {percent > 0 && <p> Uploading : {percent} %</p>}

            <ErrorMessage
              name="logo"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email">Client Email</label>
            <Field
              type="email"
              id="email"
              name="email"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="email"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="website">Client Website</label>
            <Field
              type="text"
              id="website"
              name="website"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="website"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="address">Client Address</label>
            <Field
              type="text"
              id="address"
              name="address"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="address"
              component="div"
              className="text-red-500"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="description">Client Description</label>
            <Field
              as="textarea"
              id="description"
              name="description"
              rows="4"
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-green-900"
            />
            <ErrorMessage
              name="description"
              component="div"
              className="text-red-500"
            />
          </div>
          <button
            type="submit"
            disabled={isSubmitting || isSubmittingForm}
            className="disabled:bg-green-900 disabled:cursor-not-allowed mt-4 ml-[38%] px-8 py-3 bg-green-800 rounded-md text-white text-base font-bold hover:bg-green-900"
          >
            {isSubmitting || isSubmittingForm ? "Submitting" : "Submit"}
          </button>
        </Form>
      )}
    </Formik>
  );
};

export default CompanyForm;
