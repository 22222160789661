import React from "react";

const ServicesUI = () => {
  return (
    <div className="min-h-screen text-base">
      <div className="container">
        <h1 className="text-2xl md:text-4xl font-bold mb-4">
          Services We Provide
        </h1>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {/* Service Card 1 - CIVIL & INFRASTRUCTURE */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">
              1. CIVIL & INFRASTRUCTURE
            </h2>
            <ul>
              <li>1. Residential & community development projects</li>
              <li>2. Metro & rail projects</li>
              <li>3. Road, bridges & highway projects</li>
              <li>4. Water pipeline projects</li>
              <li>5. Airport & public buildings projects</li>
              <li>6. Hospital & hotels construction</li>
            </ul>
          </div>

          {/* Service Card 2 - MECHANICAL & ELECTRICAL */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">
              2. MECHANICAL & ELECTRICAL
            </h2>
            <ul>
              <li>1. Power plant projects</li>
              <li>2. Combined cycle power & desalination projects</li>
              <li>3. Transmission line projects</li>
              <li>4. Distribution Line Projects</li>
              <li>5. Nuclear Power Plant Projects</li>
              <li>6. Refineries</li>
              <li>7. Steel Plants</li>
              <li>8. HVAC</li>
              <li>9. Batching Plants</li>
              <li>10. Heavy fabrication workshop</li>
              <li>11. Steel Plants</li>
              <li>12. Mini steel rolling mill</li>
            </ul>
          </div>

          {/* Service Card 3 - OIL, GAS & PETROLEUM */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">
              3. OIL, GAS & PETROLEUM
            </h2>
            <ul>
              <li>1. Ethylene Plant</li>
              <li>2. Oil/Gas Pipeline projects</li>
              <li>3. Gas processing plant projects</li>
              <li>4. Gas Development Projects</li>
              <li>5. Utilities & Offsite projects</li>
              <li>6. Oil pier projects</li>
              <li>7. Petrochemical projects</li>
              <li>8. Downstream plant projects</li>
              <li>9. Refinery expansion & upgradation</li>
              <li>10. Shutdown projects</li>
              <li>11. Maintenance projects</li>
              <li>12. O & G Debottlenecking projects</li>
            </ul>
          </div>

          {/* Service Card 4 - MARINE */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">4. MARINE</h2>
            <ul>
              <li>1. Causeway Projects</li>
              <li>2. Oil Jetty projects</li>
              <li>3. Barrage and ship lock projects</li>
              <li>4. Bridge projects</li>
              <li>5. Seaport projects</li>
              <li>6. Container terminal loading & off-loading facilities</li>
            </ul>
          </div>

          {/* Service Card 5 - HOSPITALITY */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">5. HOSPITALITY</h2>
            <ul>
              <li>1. Hotel Staff</li>
              <li>2. Industrial Catering</li>
            </ul>
          </div>

          {/* Service Card 6 - MEDICAL/ PARAMEDICAL */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">
              6. MEDICAL/ PARAMEDICAL
            </h2>
            <ul>
              <li>
                1. Consultants, Specialists, Residents, General Physicians in
                all branches besides Staff
              </li>
              <li>2. Nurses</li>
              <li>3. Technicians, Chemists, etc.</li>
            </ul>
          </div>

          {/* Service Card 7 - ADMIN/HR & ACCOUNTING */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">
              7. ADMIN/HR & ACCOUNTING
            </h2>
            <ul>
              <li>1. Admin Staff</li>
              <li>2. Finance Staff</li>
              <li>3. Accounting Personnel</li>
              <li>4. Document Control Staff</li>
              <li>5. Computer Operators</li>
            </ul>
          </div>

          {/* Service Card 8 - IT & TELECOMMUNICATION */}
          <div className="rounded-lg shadow border p-6">
            <h2 className="text-lg font-semibold mb-4">
              8. IT & TELECOMMUNICATION
            </h2>
            <ul>
              <li>1. EDP Managers</li>
              <li>2. System Analysts & Programmers</li>
              <li>3. Web & app developers</li>
              <li>4. Software & hardware engineers</li>
              <li>5. Technicians</li>
              <li>6. Operators</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesUI;
