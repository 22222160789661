import React from "react";

const DashboardSidebar = ({ Tabs, handleTabChange, activeTab }) => {
  return (
    <div className="bg-green-800 text-white h-screen w-[200px] fixed flex flex-col ">
      <div className="text-2xl font-bold px-4 py-6">Admin Dashboard</div>
      <nav className="flex-grow">
        <ul className="space-y-2 px-4">
          {Tabs.map((tab) => (
            <li
              className={`cursor-pointer px-2 ${
                activeTab === tab?.tabName
                  ? "font-bold bg-white text-black"
                  : "font-normal"
              }`}
              onClick={() => handleTabChange(tab?.tabName)}
            >
              {tab?.tabName}
            </li>
          ))}
        </ul>
      </nav>
    </div>
  );
};

export default DashboardSidebar;
