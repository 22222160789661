import { useState } from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { saveAs } from "file-saver";

export default function DataTable({ data, columns }) {
  const [sorting, setSorting] = useState([]);
  const [filtering, setFiltering] = useState("");

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    state: {
      sorting: sorting,
      globalFilter: filtering,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: setFiltering,
  });
  const downloadCSV = () => {
    const csvData = [];

    const columnHeaders = columns.map((column) => column.header);
    csvData.push(columnHeaders.join(","));

    data.forEach((row) => {
      const rowData = columns.map((column) => {
        const cell = row[column.accessorKey];
        return typeof cell === "string" ? cell.replace(/"/g, '""') : cell;
      });
      csvData.push(`"${rowData.join('","')}"`);
    });

    const csvContent = csvData.join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    saveAs(blob, "data.csv");
  };

  return (
    <div className="w3-container">
      <div className="flex justify-between">
        <input
          type="text"
          value={filtering}
          placeholder="Search Client"
          onChange={(e) => setFiltering(e.target.value)}
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg
         focus:ring-blue-300 focus:border-blue-300 block p-2.5"
        />
        <button
          className="bg-green-800 flex text-white px-4 py-2 rounded-lg hover:bg-green-900"
          onClick={downloadCSV}
        >
          Download CSV
        </button>
      </div>
      <table className="w3-table-all mt-3">
        <thead>
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  onClick={header.column.getToggleSortingHandler()}
                  className="cursor-pointer"
                >
                  {header.isPlaceholder ? null : (
                    <div>
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {
                        { asc: "🔼", desc: "🔽" }[
                          header.column.getIsSorted() ?? null
                        ]
                      }
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows?.length
            ? table.getRowModel().rows.map((row) => (
                <tr key={row.id}>
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id}>
                      {/* {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )} */}
                      {cell.getValue() !== null
                        ? flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )
                        : "-"}
                    </td>
                  ))}
                </tr>
              ))
            : "No data found"}
        </tbody>
      </table>
      <div className="flex justify-center text-green-800 font-bold pt-5 text-lg">
        {data.length <= 0 ? "Loading Data..." : null}
      </div>
      <div className="flex justify-end gap-5 my-5">
        <span className="flex items-center gap-1">
          <div>Page</div>
          <strong>
            {table.getState().pagination.pageIndex + 1} of{" "}
            {table.getPageCount()}
          </strong>
        </span>
        <button
          className="bg-green-800 text-white px-2 py-1 rounded-lg disabled:cursor-not-allowed disabled:bg-green-300"
          disabled={!table.getCanPreviousPage()}
          onClick={() => table.previousPage()}
        >
          Previous page
        </button>
        <button
          className="bg-green-800 text-white px-2 py-1 rounded-lg disabled:cursor-not-allowed disabled:bg-green-300"
          disabled={!table.getCanNextPage()}
          onClick={() => table.nextPage()}
        >
          Next page
        </button>
      </div>
    </div>
  );
}
