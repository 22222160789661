import React from "react";
import RequiredDocumentsUI from "./RequiredDocumentsUI ";
import RecruitMentUI from "./RecruitMentUI";
import LegalDocumentsUI from "./LegalDocumentsUI";
import ServicesUI from "./ServicesUI";
import RecruitMentProcedure from "../../assets/images/newrecruitment-procedure.png";

const AboutUs = () => {
  return (
    <div className="md:py-8 md:px-20  py-2 px-5 flex flex-col gap-10 bg-green-50">
      <div className="flex flex-col lg:flex-row justify-between gap-8">
        <div className="w-full flex flex-col justify-center gap-2 ">
          <h1 className="text-2xl md:text-4xl font-bold leading-9 pb-3 text-gray-800 ">
            About Us
          </h1>
          <div className="flex flex-col gap-4 font-normal text-base leading-6 text-gray-600 text-justify">
            <div className="">
              At RK International, we are a leading company specializing in
              managing the recruitment process and providing skilled manpower in
              various sectors, including construction, oil and gas, electrical,
              painting/insulation, rigging, scaffolding, and many more. With a
              strong focus on delivering exceptional staffing solutions, we
              strive to connect talented candidates with rewarding career
              opportunities across industries.
            </div>
            <div className="">
              <div className="text-lg font-bold text-gray-800">
                End-to-End Recruitment Solutions:
              </div>
              We offer comprehensive recruitment solutions tailored to meet the
              specific requirements of our clients. From sourcing and screening
              qualified candidates to facilitating interviews and negotiating
              offers, we take care of the entire recruitment process, allowing
              our clients to focus on their core business objectives.
            </div>
            <div className="">
              <div className="text-lg font-bold text-gray-800">
                Skilled Manpower Across Industries:
              </div>
              With an extensive database of skilled professionals, we have the
              capability to fulfill workforce demands in diverse industries.
              Whether our clients require construction experts, oil and gas
              specialists, electrical technicians, painters, insulation
              specialists, or rigging and scaffolding professionals, we have the
              expertise to deliver top-notch talent that aligns with their
              unique project requirements.
            </div>
            <div className="">
              <div className="text-lg font-bold text-gray-800">
                Support for New Candidates:
              </div>
              We understand that entering the job market or transitioning to a
              new industry can be daunting for candidates. That's why we provide
              comprehensive support to new candidates throughout the recruitment
              process. From assisting with interview document processing to
              facilitating legal document processing and travel arrangements, we
              ensure a smooth and hassle-free experience for our candidates,
              enabling them to confidently pursue their career aspirations.
            </div>
            <div className="">
              <div className="text-lg font-bold text-gray-800">
                Partnership and Trust:
              </div>
              At RK International, we value long-term partnerships and believe
              in building trust with our clients and candidates alike. We take
              pride in our commitment to excellence, professionalism, and
              integrity. Our dedicated team of recruitment experts works closely
              with clients to understand their specific needs, ensuring that we
              deliver customized solutions that exceed expectations.
            </div>
          </div>
        </div>
      </div>

      <div className="w-full md:flex flex-row justify-between shadow border rounded-lg py-5 px-6">
        <div className="w-full md:w-[60%] flex flex-col gap-2 md:gap-3 font-normal text-base leading-6 text-gray-600 text-justify">
          <div className="flex flex-col text-left ">
            <h1 className="text-2xl md:text-4xl font-bold leading-9 text-gray-800 pb-5">
              Messaage from Chairman
            </h1>
            <div className="block md:hidden justify-center items-center pb-3">
              <img
                src="manager.png"
                className="w-[400px]"
                alt="managing-director"
              />
            </div>
          </div>
          <div className="">Dear Customers and Clients,</div>
          <div className="">
            Warm Greeting from the Land of the Himalayas, Nepal. First and
            foremost, we extend our sincere thanks and gratitude for showing
            your interest towards our organization. We are indeed very glad to
            introduce ourself as one of the leading manpower recruiting
            companies operating in the Himalayan Kingdom of Nepal.
          </div>
          <div className="">
            I would like to bring it to our valued client's notice that R.K.
            International HR Solution Pvt. Ltd., with its competent staff
            members, stands for quality services in order to meet required
            demand of clients. We are very much flexible with our valued clients
            to develop the business relationship. I always consider the fact
            that the human potentiality should be utilized to the maximum extent
            for the betterment of the world. Hence, our priority is always to
            bridge up the opportunities and the human resources. In short, we
            undertake the responsibility to provide the right man for the right
            job. I look forward to working with your esteemed organization in
            the days to come for mutual benefit.
          </div>
          <div className="">
            Now, we can, here, safely declare that our ultimate goal to leave an
            indelible imprint that Nepalese workers, business world wide, be it
            for an ordinary or a spaciest job is getting truer by the day, we
            therefore holding true to the constant up gradation and quality
            improvement in imparting our services.
          </div>
          <div className="">
            Once again, I would like to thank you for choosing the R.K.
            International HR Solution Pvt. Ltd. as your business partner. We
            make sure that you will be safe with us.
          </div>
          <div className="">With warm regards !</div>
          <div className="font-bold">
            <div>Prakash AC</div>Chairman
          </div>
        </div>
        <div className="hidden md:flex pl-5 justify-center items-center">
          <img src="manager.png" className="w-[400px]" alt="chairman" />
        </div>
      </div>
      <div className="w-full flex flex-col md:flex-row justify-between shadow border rounded-lg py-5 px-6">
        <div className="w-full md:w-[60%] flex flex-col justify-center">
          <h1 className="text-2xl md:text-4xl font-bold leading-9 text-gray-800 pb-5">
            Messaage from Managing Director
          </h1>
          <div className="display md:hidden pb-5 justify-center items-center">
            <img width={400} src="executive.png" alt="executive-director" />
          </div>
          <div className="flex flex-col gap-2 md:gap-3 font-normal text-base leading-6 text-gray-600 text-justify">
            <div className="">Hearty Welcome !</div>
            <div className="">
              We (R.K. International HR Solution Pvt. Ltd.), are delighted to
              expressing our happiness in presenting and putting forth this
              Profile for the use of our clients all across the world. We hope
              it will ease our clients to know about us better!
            </div>
            <div className="">
              In order to compete in business today client(s)/employer(s) must
              strive to access high-performing and highly skilled agencies can
              maintain and fulfill appropriate candidates for foreign
              employment. Being a service provider we incorporated R.K.
              International HR Solution Pvt. Ltd. with a clear objective that is
              to serve Nepalese workforce with cost effective, timely &
              professional approach.
            </div>
            <div className="">
              Customer satisfaction is on prior that's why we are committed to
              continue impaction services to our valued clients who are actively
              involving in this business since it's establishment. And aimed to
              reach out to those who are yet to enjoy our services with
              effective means of recruitment.
            </div>
            <div className="">
              We believe that the service we provide matters a lot !
            </div>
            <div className="">
              We believe that the service we provide matters a lot ! We would
              like to take an opportunity to THANK ALL THE CLIENT(s) for
              granting hand to hand support and wish to expand our services in
              the years to come.
            </div>
            <div className="font-bold">
              <div>Bhakta Bahadur Pun</div>
              Managing Director
            </div>
          </div>
        </div>
        <div className="hidden md:flex justify-center pl-5 items-center">
          <img width={400} src="executive.png" alt="managing-director" />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-10 justify-between">
        <div className="md:w-[40%] flex text-justify justify-center items-center">
          <div className="">
            <h1 className="text-2xl lg:text-4xl font-bold leading-9 text-gray-800 pb-5">
              Our Services
            </h1>
            As we are one of the reputed recruitment agencies in Nepal, our
            focus is mainly managing I organizing the recruitment programs for
            your companies as per the requirement as well as the management of
            workers. The services asked by our clients are as different as their
            nature of work. Therefore, we are able to provide the services as
            required by you for your companies which can we summerised as
            followings.
          </div>
        </div>
        <div className="md:flex-1">
          <img width={"100%"} src="our-services.svg" alt="our-services" />
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-10 justify-between">
        <div className="flex md:w-[60%] text-justify">
          <div className="flex flex-col justify-center">
            <h1 className="text-xl lg:text-4xl font-bold leading-9 text-gray-800 pb-5">
              Recruitment Procedures
            </h1>
            As we are one of the reputed recruitment agencies in Nepal, our
            focus is mainly managing I organizing the recruitment programs for
            your companies as per the requirement as well as the management of
            workers. The services asked by our clients are as different as their
            nature of work. Therefore, we are able to provide the services as
            required by you for your companies which can we summerised as
            followings.
          </div>
        </div>
        <div className="w-full flex justify-center">
          <img
            src={RecruitMentProcedure}
            className="md:w-[100%] "
            alt="recruitment-procedure"
          />
        </div>
      </div>
      <ServicesUI />
      <RecruitMentUI />
      <RequiredDocumentsUI />
      <LegalDocumentsUI />
    </div>
  );
};

export default AboutUs;
