import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../view/firebaseConfig";
import { Link, useParams } from "react-router-dom";

const VacancyPost = () => {
  const { id } = useParams();
  const [vacancyDetails, setVacancyDetails] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchVacancyDetails = async () => {
      try {
        const vacancyRef = collection(db, "vacancies");
        const vacancySnapshot = await getDocs(vacancyRef);
        const vacancyData = vacancySnapshot.docs.map((doc, id) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const selectedVacancy = vacancyData.filter(
          (detail) => detail.id === id
        );

        if (selectedVacancy) {
          setVacancyDetails(selectedVacancy);
        } else {
          setVacancyDetails(null);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching vacancy:", error);
        setLoading(false);
      }
    };

    fetchVacancyDetails();
  }, [id]);

  return (
    <div
      id="vacancy-post"
      className="bg-green-50 relative w-full px-5 md:px-16"
    >
      {loading ? (
        <div className="bg-green-50 min-h-screen flex flex-col items-center justify-center">
          <p className="text-xl font-bold text-gray-800">Loading...</p>
          <img src="/loader.svg" alt="loader" width={150} className="mx-auto" />
        </div>
      ) : (
        <>
          <div className="md:pl-[20%] title-color text-4xl font-bold tracking-wide uppercase pb-4 text-green-800 ">
            Vacancy Details
          </div>

          {vacancyDetails ? (
            <div className="flex justify-center items-center pb-10">
              {vacancyDetails.map((vacancy) => (
                <div
                  key={vacancy.id}
                  className="flex flex-col md:flex-row gap-10 justify-center"
                >
                  <img
                    src={vacancy.detailImage}
                    alt={vacancy.jobTitle}
                    className="md:h-[150vh]"
                  />
                  <div className="md:h-[40vh] md:mt-40">
                    <div className="flex flex-col gap-2 shadow-2xl px-6 rounded-lg py-6">
                      <div className="text-lg font-bold text-green-800">
                        Position :{" "}
                        <span className="font-semibold capitalize">
                          {vacancy.position}
                        </span>
                      </div>
                      <div className="text-lg font-bold text-green-800">
                        Company Name :{" "}
                        <span className="font-semibold capitalize">
                          {vacancy.companyName}
                        </span>
                      </div>
                      <div className="text-lg font-bold text-green-800">
                        Number of Vacancy :{" "}
                        <span className="font-semibold capitalize">
                          {vacancy.vacancyNumber}
                        </span>
                      </div>
                      {vacancy?.female && (
                        <div className="text-lg font-bold text-green-800">
                          Female Vacancy :{" "}
                          <span className="font-semibold capitalize">
                            {vacancy.female}
                          </span>
                        </div>
                      )}
                      {vacancy?.male && (
                        <div className="text-lg font-bold text-green-800">
                          Male Vacancy :{" "}
                          <span className="font-semibold capitalize">
                            {vacancy.male}
                          </span>
                        </div>
                      )}
                      <div className="pt-1 justify-center flex">
                        <Link
                          to={`/apply?vacancy=${vacancy.jobTitle}`}
                          className="bg-green-800 text-white px-4 py-2 rounded-lg hover:bg-green-900"
                        >
                          Apply Now
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p>Vacancy not found</p>
          )}
        </>
      )}
    </div>
  );
};

export default VacancyPost;
