import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { Link, useLocation } from "react-router-dom";

const navigation = [
  { name: "Home", href: "/" },
  { name: "About us", href: "/about" },
  { name: "Our Clients", href: "/clients" },
  { name: "R.K. International & HR Solution", href: "/", logo: true },
  { name: "Vacancies", href: "/vacancy" },
  { name: "Send CV", href: "/apply" },
  { name: "Client Enquiry Form", href: "/inquiry" },
];

const Navbar = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("");
  const location = useLocation();

  useEffect(() => {
    const currentPath = location.pathname;
    setActiveTab(currentPath);
  }, []);

  const handleActiveTab = (tabName) => {
    setActiveTab(tabName);
  };

  return (
    <header className="inset-x-0 top-0 z-50 bg-green-50 sticky">
      <nav
        className="flex items-center justify-between p-6 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6 items-center flex-1 justify-center ">
          {navigation.map((item, index) => (
            <Link
              key={item.name}
              to={item.href}
              className={` ${
                activeTab === item.href &&
                item.name !== "R.K. International & HR Solution"
                  ? "  bg-green-800 px-2.5 py-1 rounded-sm text-white "
                  : ""
              } ${
                item.name !== "R.K. International & HR Solution" &&
                "hover:bg-green-800 hover:text-white "
              }
              ${
                item.logo ? "text-xl tracking-[0.1em]" : "text-[14px]"
              } uppercase text-green-700 font-semibold px-2.5 py-1 rounded-sm`}
              onClick={() => handleActiveTab(item.href)}
            >
              {item.name === "R.K. International & HR Solution" ? (
                <div className="flex flex-col justify-center items-center font-primaryfont bg-green-800 px-4 py-1 rounded-sm opacity-95 text-white ">
                  <div className="font-bold">R.K. International</div>
                  <div className="text-sm">HR Solution</div>
                </div>
              ) : (
                item.name
              )}
            </Link>
          ))}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-600/10">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};

export default Navbar;
